@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollable-div::-webkit-scrollbar {
  display: none;
}

.scrollable-div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input:autofill {
  background-color: transparent;
}

.rotatex {
  transform: rotateX(180deg);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input[data-com-onepassword-filled="dark"] {
  transition: background-color 5000s ease-in-out 0s;
  border-bottom: 1px solid #FFF;
  -webkit-text-fill-color: #FFF;
  color: #FFF;
  -webkit-box-shadow: 0 0 0px 1000px #00000000 inset;
  box-shadow: 0 0 0px 1000px #00000000 inset;
  outline: none;
}

.text-shadow {
  text-shadow: 0px 0px 4px #00B9F2;
}

.cld-video-player {
    .vjs-control-bar .vjs-control.vjs-progress-control-events-blocker:before,
    .vjs-progress-control.vjs-control:before {
        @apply hidden;
    }
}
